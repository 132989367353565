import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import useApiFetch from '@/use/useApiFetch.js'

const loaded = ref(false)
const viewer = ref(undefined)

const { data, get: getViewer, fetching, error } = useApiFetch('/data/viewer')

const debouncedGetViewer = useDebounceFn(getViewer, 500)

addEventListener('focus', debouncedGetViewer)

watch(data, () => {
  if (!data.value) {
    loaded.value = false
    return
  }

  viewer.value = data.value.viewer

  loaded.value = true
})

export { viewer, getViewer, fetching, error, loaded }
