import { watch } from 'vue'
import useApiFetch from '@/use/useApiFetch.js'

function createWatchedPromise(data, statusCode) {
  return new Promise((resolve) => {
    watch(data, () => {
      resolve(!!data.value)
    })
    watch(statusCode, () => {
      if (statusCode.value === 409) resolve(false)
      else resolve(true)
    })
  })
}

/**
 * Check if the value is available while registering.
 *
 * @param {string} key
 * @param {string} value
 * @returns {Promise<boolean>}
 */
export function preCheckRegister(key, value) {
  const { data, post, statusCode } = useApiFetch('/data/register/precheck', {}, false)
  post({ [key]: value })
  return createWatchedPromise(data, statusCode)
}
